import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import authModule from './modules/auth'
import userModule from './modules/user'
import programModule from './modules/program'
import layoutModule from './modules/layout'
import courseModule from './modules/course'
import adminCourseMgmt from './modules/admin/admin-course-mgmt'
import notificationModule from './modules/notification'
import scheduleModule from './modules/schedule'
import overlayModule from './modules/overlay'
import assessmentModule from './modules/assessment'
import assessmentRhdModule from './modules/assessment-rhd'
import videoAsmtModule from './modules/video-asmt'
import adminModule from './modules/admin/admin'
import videoRecorderModule from './modules/video-recorder'
import tableFilterModule from '@/store/modules/table-filter'
import videoPlayerModule from './modules/video-player'
import userGroupModule from './modules/user-group'
import adminFormModule from './modules/admin/admin-program-form'
import adminManageUsersModule from './modules/admin/admin-manage-users'
import resourceModule from './modules/resource'
import proctorModule from './modules/proctor'
import dashboardModule from './modules/dashboard/dashboard-progress'
import adminCourseProgressModule from './modules/admin/admin-course-progress'
import alertModule from './modules/alert'
import processing from './modules/processing'
import videoSubmissionModule from './modules/video-submission.js'
import adminCourseProgressFilterModule from './modules/filter/admin-course-progress'
import asmtHistoryPageFilterModule from './modules/filter/asmt-history-page'
import videoOverviewFilterModule from './modules/filter/video-overview'
import videoFeedbackModule from './modules/video-feedback'
import sharedVideoModule from './modules/shared-video'
import sharedVideoFilterModule from './modules/filter/shared-video-library'
import internalAccountFilterModule from './modules/filter/internal-accounts'
import interactionAnalyticsModule from './modules/interaction-analytics'
import videoAssessedResidentModule from './modules/filter/video-assessed-resident'
import pausedUploadModule from './modules/paused-upload'
import pausedResumeAttempt from './modules/paused-resume-attempt'
import arModule from './modules/ar/ar.js'
import arScoreModule from './modules/ar/ar-score.js'
import adminMasterScheduleFilterModule from './modules/filter/admin-master-schedule'
import adminCalendarModule from './modules/admin/admin-calendar'
import toastNotification from '@/store/notifications/toastNotification'

const persistedStateOptions = {
  reducer: state => ({
    layout: state.layout,
    auth: state.auth,
    user: state.user,
    course: state.course,
    program: state.program,
    userGroup: state.userGroup,
    admin: state.admin,
    proctor: state.proctor,
    videoFeedback: state.videoFeedback,
    videoSubmission: state.videoSubmission,
    pausedResumeAttempt: state.pausedResumeAttempt,
    ar: state.ar
  }),
  storage: window.sessionStorage
}

export default createStore({
  modules: {
    layout: layoutModule,
    auth: authModule,
    user: userModule,
    course: courseModule,
    adminCourseMgmt: adminCourseMgmt,
    notification: notificationModule,
    program: programModule,
    schedule: scheduleModule,
    overlay: overlayModule,
    assessment: assessmentModule,
    assessmentRhd: assessmentRhdModule,
    videoAsmt: videoAsmtModule,
    admin: adminModule,
    videoRecorder: videoRecorderModule,
    tableFilter: tableFilterModule,
    videoDeviceDetails: videoPlayerModule,
    userGroup: userGroupModule,
    adminForm: adminFormModule,
    adminManageUsers: adminManageUsersModule,
    resource: resourceModule,
    proctor: proctorModule,
    adminCourseProgress: adminCourseProgressModule,
    alert: alertModule,
    processing: processing,
    adminCourseProgressFilter: adminCourseProgressFilterModule,
    asmtHistoryPageFilter: asmtHistoryPageFilterModule,
    videoOverviewFilter: videoOverviewFilterModule,
    videoFeedback: videoFeedbackModule,
    sharedVideo: sharedVideoModule,
    sharedVideoLibraryFilter: sharedVideoFilterModule,
    internalAccountFilter: internalAccountFilterModule,
    interactionAnalytics: interactionAnalyticsModule,
    dashboard: dashboardModule,
    videoAssessedResidentFilter: videoAssessedResidentModule,
    videoSubmission: videoSubmissionModule,
    pausedUpload: pausedUploadModule,
    pausedResumeAttempt: pausedResumeAttempt,
    ar: arModule,
    arScore: arScoreModule,
    adminMasterScheduleFilter: adminMasterScheduleFilterModule,
    adminCalendar: adminCalendarModule,
    toastNotification: toastNotification
  },
  plugins: [createPersistedState(persistedStateOptions)]
})
